export default {

	init() {

		// -- Mobile menu -- //

			var hamburger = document.querySelector('.header__hamburger');
			var mobileMenu = document.querySelector('section.MobileMenu');
			var mobileMenuContent = mobileMenu.querySelector('.mobileMenu__content');
			var body = document.querySelector('body');

			hamburger.addEventListener('click', function() {

				hamburger.classList.add('is-active');
				mobileMenu.classList.add('active');
				body.classList.add('disableScroll');

			});

			mobileMenu.addEventListener('click', function() {

				hamburger.classList.remove('is-active');
				mobileMenu.classList.remove('active');
				body.classList.remove('disableScroll');

			});

			mobileMenuContent.addEventListener('click', function(e){

				e.stopPropagation();

			});

		// -- END -- //


		// -- Header Hide -- //

			function headerHideShowScroll() {

				var header = document.querySelector('header');
				var headerHeight = header.offsetHeight;
				var lastPosition = 0;

				var isHomepage = document.querySelector('body.Homepage');
				var isWelcome = document.querySelector('body.Welcome');

				document.addEventListener('scroll', function(e) {

					if( window.scrollY < lastPosition ) {

						if( header.classList.contains('hide') ) {

							header.classList.remove('hide');

						}

					} else if( headerHeight < window.scrollY && !header.classList.contains('hide') ) {

						header.classList.add('hide');

					}

					lastPosition = window.scrollY;

					if( isHomepage ) {

						if( (isHomepage.querySelectorAll('section')[0].offsetHeight - headerHeight) < lastPosition && !header.classList.contains('header--homebackground') ) {

							header.classList.add('header--homebackground');

						} else if( (isHomepage.querySelectorAll('section')[0].offsetHeight - headerHeight) > lastPosition && header.classList.contains('header--homebackground') ) {

							header.classList.remove('header--homebackground');

						}
						
					}

					if( isWelcome ) {

						if( headerHeight < lastPosition && !header.classList.contains('header--homebackground') ) {

							header.classList.add('header--homebackground');

						} else if( headerHeight > lastPosition && header.classList.contains('header--homebackground') ) {

							header.classList.remove('header--homebackground');

						}

					}

				});

				window.addEventListener('resize', function() {

					headerHeight = header.offsetHeight;

				});
				
			}
			headerHideShowScroll();

		// -- END -- //

	},
	finalize() {

	}

}