import slick from 'slick-carousel';
import $ from 'jquery';

export default {
	init() {
    	console.log('homepageheader js');

    	function init_bodymovin() {

			if (!window.BODYMOVIN_LOADED) {
	            setTimeout(init_bodymovin, 100);
	            return;
	        }

	        $('.lottie-animation:not(.play)').each(function(){

	        	var t = this;
	        	var animation = bodymovin.loadAnimation({
				  container: t,
				  renderer: 'svg',
				  loop: false,
				  autoplay: false,
				  path: this.dataset.json
				});

	        });

	        $('.lottie-animation.play').each(function(){
	        	
	        	var t = this;
	        	var animation = bodymovin.loadAnimation({
				  container: t,
				  renderer: 'svg',
				  loop: false,
				  autoplay: false,
				  path: this.dataset.json
				});

				animation.addEventListener('DOMLoaded', (e) => {
					animation.playSegments([1,1800], true);
				});


			 	$('.lottie-animation.play').on('mouseenter click', '#braindot-12', function (){
			        animation.playSegments([2220,2280], true);
			    });

			    $('.lottie-animation.play').on('mouseleave', '#braindot-12', function (){
			        animation.playSegments([2280,2310], true);
			    });


			    $('.lottie-animation.play').on('mouseenter click', '#braindot-11', function (){
			        animation.playSegments([2040,2100], true);
			    });

			    $('.lottie-animation.play').on('mouseleave', '#braindot-11', function (){
			        animation.playSegments([2100,2130], true);
			    });


			    $('.lottie-animation.play').on('mouseenter click', '#braindot-10', function (){
			        animation.playSegments([1860,1920], true);
			    });

			    $('.lottie-animation.play').on('mouseleave', '#braindot-10', function (){
			        animation.playSegments([1920,1950], true);
			    });


			    $('.lottie-animation.play').on('mouseenter click', '#braindot-9', function (){
			        animation.playSegments([1680,1740], true);
			    });

			    $('.lottie-animation.play').on('mouseleave', '#braindot-9', function (){
			        animation.playSegments([1740,1770], true);
			    });


			    $('.lottie-animation.play').on('mouseenter click', '#braindot-8', function (){
			        animation.playSegments([1500,1560], true);
			    });

			    $('.lottie-animation.play').on('mouseleave', '#braindot-8', function (){
			        animation.playSegments([1560,1590], true);
			    });


			    $('.lottie-animation.play').on('mouseenter click', '#braindot-7', function (){
			        animation.playSegments([1320,1380], true);
			    });

			    $('.lottie-animation.play').on('mouseleave', '#braindot-7', function (){
			        animation.playSegments([1380,1410], true);
			    });


			    $('.lottie-animation.play').on('mouseenter click', '#braindot-6', function (){
			        animation.playSegments([1140,1200], true);
			    });

			    $('.lottie-animation.play').on('mouseleave', '#braindot-6', function (){
			        animation.playSegments([1200,1230], true);
			    });


			    $('.lottie-animation.play').on('mouseenter click', '#braindot-5', function (){
			        animation.playSegments([960,1020], true);
			    });

			    $('.lottie-animation.play').on('mouseleave', '#braindot-5', function (){
			        animation.playSegments([1020,1050], true);
			    });


			    $('.lottie-animation.play').on('mouseenter click', '#braindot-4', function (){
			        animation.playSegments([780,840], true);
			    });

			    $('.lottie-animation.play').on('mouseleave', '#braindot-4', function (){
			        animation.playSegments([840,870], true);
			    });


			    $('.lottie-animation.play').on('mouseenter click', '#braindot-3', function (){
			        animation.playSegments([600,660], true);
			    });

			    $('.lottie-animation.play').on('mouseleave', '#braindot-3', function (){
			        animation.playSegments([660,690], true);
			    });


			    $('.lottie-animation.play').on('mouseenter click', '#braindot-2', function (){
			        animation.playSegments([420,480], true);
			    });

			    $('.lottie-animation.play').on('mouseleave', '#braindot-2', function (){
			        animation.playSegments([480,510], true);
			    });


			    $('.lottie-animation.play').on('mouseenter click', '#braindot-1', function (){
			        animation.playSegments([240,300], true);
			    });

			    $('.lottie-animation.play').on('mouseleave', '#braindot-1', function (){
			        animation.playSegments([300,330], true);
			    });

	        });


		}

		init_bodymovin();


		// -- carousel top -- // 

			var carouselTop = document.querySelector('.HomepageHeader--row1 .slider');

			if( carouselTop ) {

				$(carouselTop).slick({
					slidesToShow : 1,
					slidesToScroll : 1,
  					arrows: false,
  					speed: 700,
  					adaptiveHeight: true,
  					rows: false,
  					autoplay: true,
  					autoplaySpeed: 4000
				});

			}

		// -- END -- //


		// -- carousel bottom -- //

			var contentBottom = document.querySelector('.HomepageHeader--row2');

			if( contentBottom ) {

				var carouselBottom = contentBottom.querySelector('.row2__carousel');

				$(carouselBottom).slick({
					slidesToShow: 1,
					slidesToScroll: 1,
  					arrows: false,
  					speed: 700,
  					adaptiveHeight: true,
  					rows: false,
  					dots: true,
  					// autoplay: true,
  					// autoplaySpeed: 1800
				});

			}

		// -- END -- //


		// -- scroll to next section -- //

			var button = document.querySelector('.HomepageHeader__scroll');
			var section2 = document.querySelectorAll('section')[1];

			if( button && section2 ) {

				button.addEventListener('click', function() {

					$('html ,body').animate({'scrollTop': section2.offsetTop }, 'smooth');

				});

			}

		// -- END -- //

	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
