export default {

	init() {

		var container = document.querySelector('main.ArchiveCareers');

		if( container ) {

			var jobs = container.querySelectorAll('.ArchiveCareers__carrers .careers__grid .grid__single');	

			if( jobs.length > 0 ) {

				var body = document.body;
				var applyJobs = [];

				var jobsButtons = container.querySelectorAll('.ArchiveCareers__carrers .careers__grid .grid__single .Button.Button--application');
				var applySection = container.querySelector('.ArchiveCareers__apply');
				var applyContent = applySection.querySelector('.apply');
				var applyClose = applySection.querySelector('.apply__close');

				var buttonAttach = applyContent.querySelector('.button__attachment');
				var inputFile = applyContent.querySelector('input[type="file"]');
				var inputFileLabel = applyContent.querySelector('.apply__file');
				var inputEmail = applyContent.querySelector('input[type="email"]');

				var buttonSubmit = applyContent.querySelector('.button__apply');

				var formSubmit = false;
				var request = new XMLHttpRequest();
				var response;
				var requestData = [];

				function sendApply( data ) {

					request = new XMLHttpRequest();

					request.onreadystatechange = function() {

						if( request.responseText && request.readyState == 4 && request.status == 200 ) {

							response = JSON.parse(request.responseText);

							// console.log( response );

							if( response.status == 'success' ) {

								applyJobs.push( applyContent.querySelector('.apply__title span').textContent );
								buttonSubmit.insertAdjacentHTML('beforeBegin','<p class="row__callback success">Your apply has been sent</p>');


							} else if( response.status == 'error' ) {

								buttonSubmit.insertAdjacentHTML('beforeBegin','<p class="row__callback">Something go wrong please try letter</p>');

							}

							buttonSubmit.classList.remove('disable');

						}

					}

					// Set up our request
					request.open('POST', document.querySelector('footer').getAttribute('data-endpoint') );

					// Add the required HTTP header for form data POST requests
					request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

					// Finally, send our data.
					request.send(data);

				}

				buttonAttach.addEventListener('click', function() {

					inputFile.click();
					
				});

				inputFile.addEventListener('change', function() {

					if( inputFile.value ) {

						inputFileLabel.textContent = inputFile.files[0].name;

					} else {

						inputFileLabel.textContent = '';
						
					}

				});

				// -- close apply form -- //

					function ApplyFormClose() {

						body.classList.remove('disableScroll');

						applySection.classList.add('hide');
						inputFileLabel.textContent = '';
						applyContent.querySelectorAll('input').forEach( function( input,index ) {

							input.value = null;
							input.classList.remove('empty');
							input.classList.remove('error');

						});

						applySection.querySelectorAll('.row__callback').forEach( function( message,index ) {
							message.remove();
						});

					}

					applySection.addEventListener('click', function() {

						ApplyFormClose();

					});

					applyContent.addEventListener('click', e => {
						e.stopPropagation();
					})

					applyClose.addEventListener('click', function() {

						ApplyFormClose();

					});

				// -- END -- //

				jobsButtons.forEach( function(button,index) {

					button.addEventListener('click', function() {

						body.classList.add('disableScroll');
						applyContent.querySelector('.apply__title span').textContent = jobs[index].querySelector('.single__title').textContent;
						applySection.classList.remove('hide');

					});

				});

				buttonSubmit.addEventListener('click', function() {

					if( !buttonSubmit.classList.contains('disable') && !applyJobs.includes(applyContent.querySelector('.apply__title span').textContent) ) {

						buttonSubmit.classList.add('disable');

						applySection.querySelectorAll('.row__callback').forEach( function( message,index ) {
							message.remove();
						});

						// -- check fields value -- //

							if( !inputFile.value ) {

								inputFile.classList.add('empty');

							} else {

								inputFile.classList.remove('empty');

							}

							if( !inputEmail.value ) {

								inputEmail.classList.add('empty');

							} else {

								inputEmail.classList.remove('empty');

								if( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test( inputEmail.value ) ) {

									inputEmail.classList.remove('error');

								} else {

									inputEmail.classList.add('error');

								}

							}

						// -- END -- //

						if( applyContent.querySelectorAll('input.empty').length > 0 ) {

							buttonSubmit.insertAdjacentHTML('beforeBegin','<p class="row__callback">Fill required fields</p>');

						} else if( 
							!(	
								inputFile.files[0].name.split('.')[inputFile.files[0].name.split('.').length-1] == 'doc' || 
								inputFile.files[0].name.split('.')[inputFile.files[0].name.split('.').length-1] == 'docx' || 
								inputFile.files[0].name.split('.')[inputFile.files[0].name.split('.').length-1] == 'pdf' 
							) 
						) {

							buttonSubmit.insertAdjacentHTML('beforeBegin','<p class="row__callback">File type not allowed</p>');

						} else if( inputEmail.classList.contains('error') ) {

							buttonSubmit.insertAdjacentHTML('beforeBegin','<p class="row__callback">Email not validate</p>');							

						} else {

							// -- requestData -- //

								requestData = [];
								requestData.push( 'action=sendApply' );
								requestData.push( 'email='+ inputEmail.value );
								requestData.push( 'title='+ applyContent.querySelector('.apply__title span').textContent );

							// -- END -- //

							// -- Add file -- //

								var reader = new FileReader();

								reader.readAsDataURL( inputFile.files[0] );

								reader.onload = function( back ) {

									if( back.target.result ) {

										requestData.push('fileBase='+back.target.result);
										requestData.push('fileName='+inputFile.files[0].name);
										requestData.push('fileType='+inputFile.files[0].type);

										requestData = requestData.join('&');

										sendApply( requestData );

									} 

								};

								reader.onerror = function(back) {

									fileError = true;
									submit.before('<p class="form__callback form__callback--error">Błąd podczas próby załączenia pliku.('+box.files[index].name+'). Proszę spróbować ponownie załączyć plik.</p>');
									submit.attr('disabled',false).removeClass('disabled');

								};

							// -- END -- //


						}

					}


				});

			}

		}

	},
	finalize() {

	}

}