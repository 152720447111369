import select from '../../Components/Select/Select';
import lozad from 'lozad';
import $ from 'jquery';

export default {

	init() {

		var container = document.querySelector('main.ArchiveSolutions');

		if( container ) {

			select.init();
			
			var filterButtons = container.querySelectorAll('.filters__single');
			var filterSelect = container.querySelector('.ArchiveSolutions__heading .filter__select select');

			// -- button action -- //

				if( filterButtons.length > 0 ) {

					filterButtons.forEach( function( button, index ) {

						button.addEventListener('click', function() {

							if( button.classList.contains('active') ) {

								button.classList.remove('active');
								reloadContent( 'all' );
								filterSelect.value = 'all';

							} else {

								button.classList.add('active');
								reloadContent( button.getAttribute('data-id') );
								filterSelect.value = button.getAttribute('data-id');

							}

							filterButtons.forEach( function( button2, index2 ) {

								if( index != index2 ) {

									button2.classList.remove('active');

								}

							});

						});

					});

				}

			// -- END -- //



			// -- select action -- //

				filterSelect.addEventListener('change', function() {

					reloadContent( filterSelect.value );

					filterButtons.forEach( function( button,index ) {

						if( filterSelect.value == button.getAttribute('data-id') ) {

							button.classList.add('active');

						} else {

							button.classList.remove('active');

						}

					});

				});

			// -- END -- //



			// -- generateNewUrl -- //

				var basicUrl = window.location.origin + window.location.pathname;
				var newUrl = '';

				function generateUrl( categoryID ) {

					newUrl = basicUrl;

					window.history.pushState( { } ,'IMSNY - Solutions', newUrl+'?category='+categoryID );

					if( categoryID != 'All' ) {

						newUrl += '?category='+categoryID+"&ajax=yes";

					} else {

						newUrl += "?ajax=yes";

					}

					return newUrl;

				}

			// -- END -- //


			// -- reload content -- //

				var xhr = new XMLHttpRequest();
				var xmlDoc = null;
				function reloadContent( categoryID ) {

					xhr = new XMLHttpRequest();
					xhr.onreadystatechange = function() {

						if( xhr.readyState == 4 && xhr.status == 200) {

	            			var xmlDoc = document.implementation.createHTMLDocument("example");
							xmlDoc.documentElement.innerHTML = xhr.responseText;

							container.querySelectorAll('.list--reload').forEach( function( box,index ) {

								box.remove();

							});

							xmlDoc.querySelectorAll('.ArchiveSolutions__list .list--reload').forEach( function( box,index ) {

								container.querySelector('.ArchiveSolutions__list').insertBefore(box, container.querySelector('.ArchiveSolutions__list .list--reload') );

							});

							var pictureObserverAfterLoadDOM = lozad('.lazy-load-item', {
				    			threshold: 0
							});
							pictureObserverAfterLoadDOM.observe();

	        			} 

					}
					xhr.open( 'GET', generateUrl( categoryID ), true );
					xhr.send();

				}

			// -- END -- //


			// -- Window on back -- //

				const listener = (href) => {

					window.location.href = window.location.href;

				};

				window.addEventListener('popstate', listener);

			// -- END -- //

		}

	},	
	finalize() {

	}

}