import select from '../../Components/Select/Select';

export default {

	init() {

		var container = document.querySelector('main.Partners');

		if( container ) {

			select.init();

			// -- show extra description -- //

				var buttons = container.querySelectorAll('.company__show');
				var extraDescriptions = container.querySelectorAll('.company__extraDescription');

				buttons.forEach( function( button,index ) {

					button.addEventListener('click', function() {

						if( !button.classList.contains('open') ) {

							extraDescriptions[index].style.height = extraDescriptions[index].querySelector('div').offsetHeight + 'px';
							button.classList.add('open');

						} else {

							extraDescriptions[index].style.height = '0px';
							button.classList.remove('open');

						}

					});

				});

			// -- END -- //



			// -- filters -- //

				var filterButtons = container.querySelectorAll('.filters__button');
				var filterSelect = container.querySelector('.filters__select select');
				var groups = container.querySelectorAll('.groups__single');

				function filterGroups( value ) {

					filterButtons.forEach( function( button,index ) {

						if( button.getAttribute('data-id') == value ) {

							button.classList.add('active');

						} else {

							button.classList.remove('active');

						}

					});

					filterSelect.value = value;

					if( value != 'all' ) {

						groups.forEach( function( group,index ) {

							if( group.getAttribute('data-id') == value ) {

								group.classList.remove('hide');

							} else {

								group.classList.add('hide');

							}

						});

					} else {

						groups.forEach( function( group,index ) {

							group.classList.remove('hide');

						});

					}

				}

				filterButtons.forEach( function( button,index ) {

					button.addEventListener('click', function() {

						filterGroups( button.getAttribute('data-id') );

					});

				});

				filterSelect.addEventListener('change', function() {

					filterGroups( filterSelect.value );

				});

			// -- END -- //

		}

	},	
	finalize() {

	}

}