import slick from 'slick-carousel';
import $ from 'jquery';

export default {

	init() {

		var container = document.querySelector('.NumberSection');

		if( container ) {

			// -- slick carousel -- //

				var carousel = container.querySelector('.carusel__items');
				var buttonNext = container.querySelector('.numberSection__button--next');
				var buttonPrev = container.querySelector('.numberSection__button--prev');

				$(carousel).slick({
					slidesToShow: 3,
					slidesToScroll: 3,
					rows: 0,
					arrows : false,
					dots: true,
					responsive: [
						{
							breakpoint: 1201,
							settings : {
								slidesToScroll: 2,
								slidesToShow: 2,
							}
						},
						{
							breakpoint: 441,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
							}
						}
					]
				});

				buttonNext.addEventListener('click', function() {

					$(carousel).slick('slickNext');

				});

				buttonPrev.addEventListener('click', function() {

					$(carousel).slick('slickPrev');

				});

			// -- END -- //


		}

	},
	finalize() {

	}

}