import slick from 'slick-carousel';
import $ from 'jquery';

export default {

	init() {

		var container = document.querySelector('.RelatedArticles');

		if( container ) {

			// -- slick carousel -- //

				var carousel = container.querySelector('.relatedArticles__carousel');

				$(carousel).slick({
					slidesToShow: 3,
					slidesToScroll: 3,
					arrows: false,
					dots: true,
					rows: false,
					responsive: [
						{
							breakpoint: 1201,
							settings : {
								slidesToShow: 2,
								slidesToScroll: 2,
							}
						},
						{
							breakpoint: 721,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
							}
						}
					]
				})

			// -- END -- //


		}

	},
	finalize() {

	}

}