import $ from 'jquery';

export default {

	init() {

		var selects = document.querySelectorAll('.select');

		function selectResize( select ) {

			var text = '';
			var tempWidth;

			select.querySelectorAll('option').forEach( function( single,index ) {

				if( single.getAttribute('value') == select.value ) {

					text = single.innerText;

				}

			});

	        // create test element
	        var temp = $('<span class="select__style">').html(text);

	        // add to body, get width, and get out
	        temp.appendTo( select.parentNode );
	        tempWidth = temp.innerWidth();
	        // temp.remove();
	        // // set select width
	        select.style.width = tempWidth + parseFloat(getComputedStyle(select)['paddingRight'] ) + parseFloat(getComputedStyle(select)['paddingLeft'] ) + 'px';

		}

		function selectAction( container ) {

			selectResize( container.querySelector('select') );

			container.querySelector('select').addEventListener('change', function() {

				selectResize( container.querySelector('select') );

			});

		}

		if( selects.length > 0 ) {

			selects.forEach( function( select,index ) {

				selectAction( select );

			});

			window.addEventListener('resize', function() {

				selects.forEach( function( select, index ) {

					selectResize( select.querySelector('select') );

				});

			});

		}

	},
	finalize() {

	}

}