import $ from 'jquery';
import RelatedArticles from '../../Sections/RelatedArticles/RelatedArticles';

export default {

	init() {

		var container = document.querySelector('main.Welcome');

		if( container ) {

			RelatedArticles.init();

			// -- section header -- //

				var buttonScroll = container.querySelector('section.Welcome__header .header__scroll');
				var section2 = document.querySelectorAll('section')[1];

				if( buttonScroll && section2 ) {

					buttonScroll.addEventListener('click', function() {

						$('html ,body').animate({'scrollTop': section2.offsetTop }, 'smooth');

					});

				}

			// -- END -- //

		}

	},
	finalize() {

	}

}