// Defaults
import './style.scss';
import Router from './scripts/Router';
import LazyLoad from './scripts/lazyLoad';
import Subpage from '../src/Layout/Subpage';

// Pages
import homepage from '../src/Pages/Homepage/Homepage';
import archivesolutions from '../src/Pages/ArchiveSolutions/ArchiveSolutions';
import archivenews from '../src/Pages/ArchiveNews/ArchiveNews';
import contact from '../src/Pages/Contact/Contact';
import partners from '../src/Pages/Partners/Partners';
import archivecareers from '../src/Pages/ArchiveCareers/ArchiveCareers';
import welcome from '../src/Pages/Welcome/Welcome';

Subpage.init();
LazyLoad.init();

const routes = new Router({
	homepage,
	archivesolutions,
	archivenews,
	contact,
	partners,
	archivecareers,
	welcome,
});

document.addEventListener("DOMContentLoaded", function() {
	routes.loadEvents();
});