import ContactForm from '../../Sections/ContactForm/ContactForm';

export default {
	init() {

		ContactForm.init();

	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
