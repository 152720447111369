export default {

	init() {

		var container = document.querySelector('section.ContactForm');

		if( container ) {

			var form = container.querySelector('.ContactForm__form');
			var fields = form.querySelectorAll('.ContactForm__field');
			
			// -- Send form -- //

				var submitButton = container.querySelector('.ContactForm__submit');
				var formSubmit = false;

				var request = new XMLHttpRequest();
				var response;
				var requestData = [];

				form.addEventListener('submit', function(e) {

					e.preventDefault();

					if( !formSubmit && !submitButton.classList.contains('disable') ) {

						submitButton.classList.add('disable');

						container.querySelectorAll('.ContactForm__callback').forEach( function( message,index ) {
							message.remove();
						});
						
						fields.forEach( function( input, index ) {

							if( input.classList.contains('ContactForm__field--required') ) {

								if( !input.value ) {

									input.classList.add('error');
									input.insertAdjacentHTML('afterend','<p class="ContactForm__callback">'+input.getAttribute('data-empty')+'</p>');

								} else {

									input.classList.remove('error');

								}

								if( input.type == 'email' && input.value ) {

									if( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test( input.value ) ) {

										input.classList.remove('error');

									} else {

										input.classList.add('error');
										input.insertAdjacentHTML('afterend','<p class="ContactForm__callback">'+input.getAttribute('data-error')+'</p>');

									}

								}

							}

						});

						if( form.querySelectorAll('.ContactForm__field.error').length > 0 ) {

							submitButton.classList.remove('disable');

						} else {

							// -- request data -- //

								requestData = [];
								requestData.push( 'action=sendMail' );
								requestData.push( 'security='+container.querySelector('input#authorizeContactForm').value );

								fields.forEach( function( input, index ) {

									requestData.push( input.getAttribute('name')+'='+input.value );

								});

								requestData = requestData.join('&');

							// -- END -- //



							// -- request -- //

									request = new XMLHttpRequest();

									request.onreadystatechange = function() {

										if( request.responseText && request.readyState == 4 && request.status == 200 ) {

											response = JSON.parse(request.responseText);

											console.log( response );

											if( response.status == 'success' ) {

												formSubmit = true;
												submitButton.insertAdjacentHTML('beforebegin','<p class="submitBox__messageSend">Your message has been sent</p>');

											} else if( response.status == 'error' ) {

												submitButton.classList.remove('disable');

											}

										}

									}

									// Set up our request
									request.open('POST', document.querySelector('footer').getAttribute('data-endpoint') );

									// Add the required HTTP header for form data POST requests
									request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

									// Finally, send our data.
									request.send(requestData);

								// -- END -- //

						}

					}

				});

			// -- END -- //


		}

	},
	finalize() {

	}

}