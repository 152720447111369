import select from '../../Components/Select/Select';
import lozad from 'lozad';
import $ from 'jquery';

export default {

	init() {

		var container = document.querySelector('main.ArchiveNews');

		if( container ) {

			select.init();

			var header = document.querySelector('header');

			var buttonFilters = container.querySelectorAll('.header__row .filter__single');
			var selectFilter = container.querySelector('.ArchiveNews__heading .header__row .filter__select select');
			// -- category button action -- //

				if( buttonFilters ) {

					buttonFilters.forEach( function( button,index ) {

						button.addEventListener('click', function() {

							container.setAttribute('data-page_num', 1);

							if( button.classList.contains('active') ) {

								button.classList.remove('active');
								container.setAttribute( 'data-category','' );
								reloadContent();
								selectFilter.value = 'all';

							} else {

								button.classList.add('active');
								container.setAttribute( 'data-category',button.getAttribute('data-id') );
								reloadContent();
								selectFilter.value = button.getAttribute('data-id');

							}

							buttonFilters.forEach( function( button2,index2 ) {

								if( index != index2 ) {

									button2.classList.remove('active');

								}

							});

						});

					});

				}

			// -- END -- //


			
			// -- select action -- //

				selectFilter.addEventListener('change', function() {

					container.setAttribute( 'data-category', selectFilter.value );
					reloadContent();

					buttonFilters.forEach( function( button,index ) {

						if( button.getAttribute('data-id') == selectFilter.value ) {

							button.classList.add('active');
							
						} else {

							button.classList.remove('active');

						}

					});

				});

			// -- END -- //



			// -- pagination -- //

				document.addEventListener('click', function(e) {

					if( e.target.classList.contains('pagination__button') ) {

						container.setAttribute('data-page_num',e.target.getAttribute('data-page'));
						reloadContent();

						$('html ,body').animate({'scrollTop': (container.offsetTop - header.offsetHeight) }, 'smooth');

					}

				});

			// -- END -- //



			// -- search input -- //

				var inputField = container.querySelector('input[name="search"]');
				var inputSubmit = container.querySelector('.header__row .row__search button');

				if( inputField ) {

					var inputInterval;

					inputField.addEventListener('input', function() {

						clearInterval( inputInterval );
						inputInterval = null;

						inputInterval = setTimeout( 
							function() {

								container.setAttribute('data-page_num', 1);
								container.setAttribute('data-search', inputField.value);
								reloadContent();

							},	
							500
						);

					});

					inputField.addEventListener('keypress', function(e) {

						if( e.key == "Enter" ) {

							clearInterval( inputInterval );
							inputInterval = null;

							container.setAttribute('data-page_num', 1);
							container.setAttribute('data-search', inputField.value);
							reloadContent();

						}

					});

					inputSubmit.addEventListener('click', function() {

						clearInterval( inputInterval );
						inputInterval = null;

						container.setAttribute('data-page_num', 1);
						container.setAttribute('data-search', inputField.value);
						reloadContent();
						
					});

				}

			// -- END -- //



			// -- generateNewUrl -- //

				var basicUrl = window.location.origin + window.location.pathname;
				var newUrl = '';
				var args = [];
				function generateUrl() {

					newUrl = basicUrl;
					args = [];

					if( container.getAttribute('data-category') ) {

						args.push( 'category='+container.getAttribute('data-category') );

					} 

					if( container.getAttribute('data-search') ) {

						args.push( 'search='+container.getAttribute('data-search') );

					}

					args.push( 'page_num='+container.getAttribute('data-page_num') );

					newUrl += '?'+args.join('&');

					window.history.pushState( { } ,'IMSNY - News', newUrl );

					return newUrl;

				}

			// -- END -- //



			// -- reload content -- //

				var xhr = new XMLHttpRequest();
				var xmlDoc = null;
				function reloadContent() {

					xhr = new XMLHttpRequest();
					xhr.onreadystatechange = function() {

						if( xhr.readyState == 4 && xhr.status == 200) {

	            			var xmlDoc = document.implementation.createHTMLDocument("example");
							xmlDoc.documentElement.innerHTML = xhr.responseText;

							container.querySelectorAll('.list--reload').forEach( function( box,index ) {

								box.remove();

							});

							xmlDoc.querySelectorAll('.ArchiveNews__list .list--reload').forEach( function( box,index ) {

								container.querySelector('.ArchiveNews__list').insertBefore(box, container.querySelector('.ArchiveSolutions__list .list--reload') );

							});

							var pictureObserverAfterLoadDOM = lozad('.lazy-load-item', {
				    			threshold: 0,
				    			loaded: function(el) {
				                    el.classList.add('asset-loaded');
				                }
							});
							pictureObserverAfterLoadDOM.observe();

	        			} 

					}

					xhr.open( 'GET', generateUrl()+'&ajax=yes', true );
					xhr.send();

				}

			// -- END -- //



			// -- Window on back -- //

				const listener = (href) => {

					window.location.href = window.location.href;

				};

				window.addEventListener('popstate', listener);

			// -- END -- //

		}

	},	
	finalize() {

	}

}