import RelatedArticles from '../../Sections/RelatedArticles/RelatedArticles';
import NumberSection from '../../Sections/NumberSection/NumberSection';
import HomepageHeader from '../../Sections/HomepageHeader/HomepageHeader';

export default {
	init() {

		RelatedArticles.init();
		NumberSection.init();
		HomepageHeader.init();

	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
